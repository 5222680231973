@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

:root {
  --block-1: 100%;
  --white-block-1: 0%;
  --block-2: 100%;
  --white-block-2: 0%;
  --block-3: 100%;
  --white-block-3: 0%;
  --block-4: 100%;
  --white-block-4: 0%;
  --block-5: 100%;
  --white-block-5: 0%;
  --block-6: 100%;
  --white-block-6: 0%;
  --block-7: 100%;
  --white-block-7: 0%;
  --left-value: 0;
}

body {
  margin: 0;
    font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

body * {
    font-family: 'Open Sans', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.potential__range {
  color: #1d1d1d;
}
.potential__range h3 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.potential__range h3 b {
  text-transform: capitalize;
}
.potential__range .range {
  display: flex;
}
.potential__range .range .item {
  width: calc(100% / 7);
  height: 21px;
  position: relative;
  background-color: rgba(200, 207, 213, 0.5);
}
.potential__range .range .item .indicator {
  width: 90px;
  height: 32px;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.08);
  border: solid 2px #fff;
  border-radius: 16px;
  position: absolute;
  left: calc(var(--left-value) - (90px / 2));
  top: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.potential__range .range .item .indicator.low {
  background-color: #f8e2dd;
}
.potential__range .range .item .indicator.low span {
  color: #f14913;
}
.potential__range .range .item .indicator.low::after {
  border-top: 8px solid #f8e2dd;
}
.potential__range .range .item .indicator.medium {
  background-color: #fff7e3;
}
.potential__range .range .item .indicator.medium span {
  color: #fbaf00;
}
.potential__range .range .item .indicator.medium::after {
  border-top: 8px solid #fff7e3;
}
.potential__range .range .item .indicator.high {
  background-color: #e3f5ea;
}
.potential__range .range .item .indicator.high span {
  color: #43c770;
}
.potential__range .range .item .indicator.high::after {
  border-top: 8px solid #e3f5ea;
}
.potential__range .range .item .indicator span {
  font-size: 1.3rem;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.potential__range .range .item .indicator::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 140%);
}
/* .potential__range .range .item .indicator::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 140%); */
/* } */
/* .potential__range .range .item:not(:last-child) {
  margin-right: 0.2rem;
} */
.potential__range .range .item.filled {
  position: relative;
}
.potential__range .range .item.filled.current::before {
  content: attr(data-content);
  height: 24px;
  width: 32px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  background-color: red;
  position: absolute;
  top: 50%;
  left: calc(var(--left-value) - (38px / 2));
  transform: translateY(-50%);
  z-index: 1;
  font-size: 12px;
  font-weight: bold;
}
/* .potential__range .range .item.filled.current::after {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: calc(var(--left-value) - (16px / 2));
  transform: translateY(-50%);
  z-index: 2;
} */
.potential__range .range .item.filled:nth-child(1) {
  background: linear-gradient(90deg, #f14913 var(--block-1), rgba(200, 207, 213, 0.5) var(--white-block-1));
}
.potential__range .range .item.filled:nth-child(1).current::before {
  background: #f14913;
}
.potential__range .range .item.filled:nth-child(2) {
  background: linear-gradient(90deg, #f17613 var(--block-2), rgba(200, 207, 213, 0.5) var(--white-block-2));
}
.potential__range .range .item.filled:nth-child(2).current::before {
  background: #f17613;
}
.potential__range .range .item.filled:nth-child(3) {
  background: linear-gradient(90deg, #f19e13 var(--block-3), rgba(200, 207, 213, 0.5) var(--white-block-3));
}
.potential__range .range .item.filled:nth-child(3).current::before {
  background: #f19e13;
}
.potential__range .range .item.filled:nth-child(4) {
  background: linear-gradient(90deg, #f1b113 var(--block-4), rgba(200, 207, 213, 0.5) var(--white-block-4));
}
.potential__range .range .item.filled:nth-child(4).current::before {
  background: #f1b113;
}
.potential__range .range .item.filled:nth-child(5) {
  background: linear-gradient(90deg, #f1de13 var(--block-5), rgba(200, 207, 213, 0.5) var(--white-block-5));
}
.potential__range .range .item.filled:nth-child(5).current::before {
  background: #f1de13;
}
.potential__range .range .item.filled:nth-child(6) {
  background: linear-gradient(90deg, #ccd948 var(--block-6), rgba(200, 207, 213, 0.5) var(--white-block-6));
}
.potential__range .range .item.filled:nth-child(6).current::before {
  background: #ccd948;
}
.potential__range .range .item.filled:nth-child(7).current::before {
  background: #43c770;
}
.potential__range .range .item.filled:nth-child(7) {
  
  background: linear-gradient(90deg, #43c770 var(--block-7), rgba(200, 207, 213, 0.5) var(--white-block-7));
}
.potential__range .range .item.filled:nth-child(7) .indicator {
  right: -30px;
}
.potential__range .range .item.empty.current::before {
  content: '';
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  background-color: red;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  z-index: 1;
}
/* .potential__range .range .item.empty.current::after {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 1px;
  transform: translateY(-50%);
  z-index: 2;
} */
.potential__range .range .item.empty .indicator {
  left: -15px;
}
.potential__range .range .item:nth-child(7) svg {
  position: absolute;
  right: -24px;
  top: 50%;
  transform: translateY(-50%);
}
