.ruleGroup-combinators,
.ruleGroup-addRule,
.ruleGroup-addGroup {
  /* margin-right: 10px; */
}

.ruleGroup,
.rule {
  /* margin-top: 0.5rem;
  margin-left: 0.5rem; */
}

.ruleGroup {
  /* padding: 0.5rem;
  border: 1px solid #7f81a2;
  background: rgba(0, 75, 183, 0.2);
  border-bottom: transparent; */
}

.rule-fields,
.rule-operators,
.ruleGroup-combinators {
  -webkit-appearance: none;
  -moz-appearance: none;
  min-width: 165px;
  padding: 8px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

.rule-fields {
  min-width: 350px;
}

.rule-fields:focus,
.rule-operators:focus,
.ruleGroup-combinators:focus {
  /* outline: none;
  border: none; */
}

input[type="text"].rule-value,
input[type="number"].rule-value {
  min-width: 200px;
  padding: 8px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.rule-value input[type="radio"]:checked {
  border: 6px solid #007bff;
  outline: unset !important;
}
.rule-value input[type="radio"] {
  /* margin-right: 15px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #999;
  transition: 0.2s all linear;
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.rule-value > label {
  margin-right: 10px;
}

.rule-value > label:last-child {
  margin-right: 0;
}

.rule-operators {
  min-width: 125px;
}

.rule-value {
  /* margin-right: 15px; */
}

.rule-remove,
.ruleGroup-remove {
  background: #c30000;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 7px;
  line-height: 1.4;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  position: relative;
}

input,
select {
  /* display: inline;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 7px;
  outline: none; */
}

input[type="checkbox"] {
  /* margin-top: 10px;
  transform: scale(1.3); */
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  border-radius: 0.25rem;
}